import useSWR from 'swr';

import { fetcher } from '@/helpers/fetcher';
import { API_HOSTNAME as API, API_TABLE_TYPE as T } from '@/constants/api';
import normalizeGuest from './normalizer';

/**
 * @function useGuestList
 * @description a custom hook to get all guest from BE
 */
function useGuestList() {
  const { data, isLoading, error } = useSWR(API + `?table_name=${T.rsvp}&action=read`, fetcher);

  return { error, isLoading, data: normalizeGuest(data?.data), isSuccess: data?.success };
}

export default useGuestList;
